import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/blood-rage-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1987 Thanksgiving slasher movie, Blood Rage"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 26</h1>
                    <h2>Blood Rage</h2>
                    <h3>November 27, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> John Girssmer // <b>Starring:</b> Louise Lasser &amp; Mark Soper</p>
                        <div className="player">
                        <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/26-Blood-Rage-e2cf9tg" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe! horror podcase episode 26, Blood Rage"></iframe>
                        </div>
                        <p><Link to="/transcripts/blood-rage">Read the episode transcript</Link></p>
                        <p>Just in time for Thanksgiving, Bryan and Dave dive head-first into the wildly inconsistent shit show that is Blood Rage. Marvel at the utterly deranged performance from Louise Lasser, thrill to the low budget gory special effects that are way better than the movie deserves, cringe like you've never cringed before to the movie's many intimate and sexy scenes that encapsulate the early 1980's like few other horror movies. It's a real journey.</p>
                        <p>Trailer: <OutboundLink href="https://www.youtube.com/watch?v=3jwQ11osTWw" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=3jwQ11osTWw</OutboundLink></p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/hellraiser">Next Episode</Link></li>
                        <li><Link to="/episodes/elves">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)